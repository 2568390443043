import React, { useEffect, memo } from "react";

import SEO from "../components/layout/seo";
import SubHeader from "../components/layout/subheader";

import Space from "../components/space";

import Aos from 'aos';

import { useStaticQuery, graphql } from 'gatsby';

import Img from "gatsby-image";




const NossosServicosBackground = ({name, children}) =>
{
    const data = useStaticQuery(graphql`
        query {
            background: file(relativePath: { eq: "nossos-servicos.png" }) {
                childImageSharp {
                    fluid(maxWidth: 479, maxHeight: 528) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    if ( name === "nossos-servicos.png" ) {
        return <Img fluid={data.background.childImageSharp.fluid} alt="" />
    }
}






const onFocus = () =>
{
    //console.log('PaginaNossosServicos:','onFocus');
    Aos.refresh();
}

// <div className={`subheader ${bg}`}>

const TempPaginaNossosServicos = () =>
{
    useEffect(() =>
    {
        //
        // componentDidMount

        //console.log('PaginaNossosServicos:','render');

        if ( typeof Aos !== `undefined` && typeof window !== `undefined` ) {
            setTimeout(function() {
                //Aos.refresh();
                Aos.init();
            }, 500);
            window.addEventListener("focus", onFocus);
        }

        //
        // componentWilUnmount

        return () => {
            //console.log('PaginaNossosServicos:','unmount');

            if ( typeof Aos !== `undefined` && typeof window !== `undefined` )
            {
                window.removeEventListener("focus", onFocus);
                //Aos.disable();
            }
        };
    }, []);

    return (
        <>
            <SEO title="Nossos servicos" />
            <SubHeader pagename="Nossos servicos" bg="page-bg-nossos-servicos.jpg" />

            <div style={{marginBottom: 100}}></div>
            <div className="page__body page__nossos-servicos">
                <div className="page__content">
                    <div className="page__background"><Space altura={30} /><NossosServicosBackground name="nossos-servicos.png" /></div>
                    <h2>Restituição fiscal para<br />empresas com todos os regimes tributários:</h2>
                    <p className="text-container">Com serviço de inteligência fiscal e tributária, levamos ao cliente soluções tributárias que visam aumentar a competitividade no mercado e recuperar créditos tributários administrativamente, com segurança e agilidade para empresas de todos os regimes tributários.</p>
                    <p className="text-container">-Pagamento no êxito;</p>
                    <p className="text-container">-Seguro de responsabilidade civil;</p>
                    <p className="text-container">-Caso a empresa seja do SIMPLES NACIONAL, a restituição pode ser feita em dinheiro e é possível fazer uma simulação sem custo;</p>
                </div>
            </div>
            <div style={{marginBottom: 100}}></div>
        </>
    )
}

const PaginaNossosServicos = memo(TempPaginaNossosServicos);

export default PaginaNossosServicos;